import {
  TextField,
  MenuItem,
  Divider,
  Skeleton,
  StandardTextFieldProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import getCountries from "../../network/get-countries";
import sortCountries from "./sortCountries";

interface CountrySelectorProps extends StandardTextFieldProps {
  language: Language;
  value: string;
}

const CountrySelector = forwardRef<HTMLInputElement, CountrySelectorProps>(
  ({ language, value, ...rest }, ref) => {
    const { t } = useTranslation();

    const { data: countries, isLoading } = useQuery({
      queryKey: ["countries", language],
      queryFn: () => getCountries(language),
    });

    /**
     * Preselect with given country
     */
    useEffect(() => {
      if (isLoading) {
        return;
      }

      if (!countries) {
        return;
      }

      if (!countries.find((country) => country.code.toLowerCase() === value)) {
        return;
      }
    }, [value, countries, isLoading]);

    if (isLoading) {
      return <Skeleton height={84} />;
    }

    const refinedCountries = (countries ?? []).sort(
      sortCountries(config.ALLOWED_COUNTRIES)
    );

    return (
      <TextField
        ref={ref}
        select
        label={t("step.companyInformation.countryLabel")}
        value={value}
        {...rest}
      >
        {refinedCountries.map((country, i) => {
          const dividerIndex = config.ALLOWED_COUNTRIES.length - 1;
          return [
            <MenuItem key={country.code} value={country.code.toLowerCase()}>
              {country.label}
            </MenuItem>,
            dividerIndex === i ? <Divider /> : null,
          ];
        })}
      </TextField>
    );
  }
);

export default CountrySelector;
