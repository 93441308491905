interface Country {
  code: string;
  label: string;
}

const sortCountries = (allowed: string[]) => (a: Country, b: Country) => {
  const aCode = a.code.toLowerCase();
  const bCode = b.code.toLowerCase();

  if (allowed.includes(aCode) && allowed.includes(bCode)) {
    return a.label.localeCompare(bCode);
  }

  if (allowed.includes(aCode)) {
    return -1;
  }

  if (allowed.includes(bCode)) {
    return 1;
  }

  return a.label.localeCompare(b.label);
};

export default sortCountries;
