import { Box, BoxProps, styled } from "@mui/system";
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as stripeJs from "@stripe/stripe-js";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

const ElementsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  "& .StripeElement": {
    // Inspired from https://github.com/mui/material-ui/blob/547b76032b890d623e3b778c3fffc7b8a924c46b/packages/mui-material/src/OutlinedInput/OutlinedInput.js#L42
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.23)"
        : "rgba(255, 255, 255, 0.23)",
    borderRadius: `${theme.shape.borderRadius}px`,
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "9.5px 14px",

    "&--focus": {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
      padding: "8.5px 13px", // Decrease padding since border width increases
    },
    "&--invalid": {
      borderColor: theme.palette.error.main,
    },
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
  },
}));

interface CardInputProps {
  language: Language;
  onLoad: (
    stripe: stripeJs.Stripe | null,
    elements: stripeJs.StripeElements | null
  ) => void;
  onChange?: ({ complete }: CardInputChangeEvent) => void;
}

const CardInput = ({ language, onLoad, onChange }: CardInputProps) => {
  const onCardInputChange = (e: stripeJs.StripeCardElementChangeEvent) => {
    if (!onChange) {
      return null;
    }
    onChange({ complete: e.complete });
  };
  return (
    <ElementsContainer>
      <Elements stripe={stripe} options={{ locale: language }}>
        <ElementsConsumer>
          {({ stripe, elements }) => {
            return (
              <CardElement
                onReady={(element) => {
                  element.focus();
                  onLoad(stripe, elements);
                }}
                onChange={onCardInputChange}
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "16px",
                      lineHeight: "35px",
                    },
                  },
                }}
              />
            );
          }}
        </ElementsConsumer>
      </Elements>
    </ElementsContainer>
  );
};

export default CardInput;
