import {
  TextField,
  Stack,
  Box,
  useTheme,
  Theme,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const borderColor = (theme: Theme, error: boolean, focus: boolean) => {
  if (error) {
    return theme.palette.error.main;
  }
  if (focus) {
    return theme.palette.primary.main;
  }
  return "rgba(0, 0, 0, 0.23)";
};

interface CodeInputProps {
  code: string;
  label: string;
  error?: boolean;
  errorMessage?: string | null;
  onChange: () => void;
  onBlur: () => void;
}

const CodeInput = React.forwardRef<HTMLInputElement, CodeInputProps>(
  ({ code, label, error = false, errorMessage, onChange, onBlur }, ref) => {
    const [focus, setFocus] = useState(false);
    const theme = useTheme();
    if (!ref || typeof ref === "function") {
      return null;
    }
    return (
      <Stack
        onBlur={onBlur}
        direction="column"
        display="flex"
        alignItems="center"
      >
        <TextField
          autoFocus
          inputRef={ref}
          className="verificationCodeInput"
          required
          inputProps={{
            pattern: "^[0-9]{6}$",
            minLength: 6,
            maxLength: 6,
            size: 6,
          }}
          sx={{
            height: 0,
            width: 0,
            opacity: 0,
            position: "absolute",
            input: { cursor: "normal" },
          }}
          onChange={onChange}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => setFocus(false)}
        />
        <Typography mb={1}>{label}</Typography>
        <Stack
          display="flex"
          direction="row"
          sx={{
            justifyContent: "space-between",
            width: 242,
          }}
        >
          {[0, 0, 0, 0, 0, 0].map((_, i) => (
            <Box
              key={i}
              onClick={() => ref.current?.focus()}
              sx={{
                border: "1px solid",
                borderColor: borderColor(
                  theme,
                  error,
                  focus && (i === ref.current?.value.length ?? -1)
                ),
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                fontSize: 32,
                padding: "1px 0",
                width: 32,
                height: 44,
                lineHeight: "44px",
                textAlign: "center",
              }}
            >
              {code.split("")[i] ?? null}
            </Box>
          ))}
        </Stack>
        {error && (
          <Typography color={theme.palette.error.main} variant="caption">
            {errorMessage}
          </Typography>
        )}
      </Stack>
    );
  }
);

export default CodeInput;
