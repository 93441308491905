import {
  CookieStorage,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUser,
} from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? "",
};

export const getCookieStorage = () => {
  const data = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN ?? "localhost",
    secure: process.env.REACT_APP_COOKIE_STORAGE_SECURE === "true",
  };
  return new CookieStorage(data);
};

export const getUserPool = () => {
  const data = {
    ...poolData,
    Storage: getCookieStorage(),
  };
  return new CognitoUserPool(data);
};

export const getCurrentUser = () => {
  return getUserPool().getCurrentUser();
};

export const getUserToken = async (): Promise<string | null> => {
  const user = getCurrentUser();
  if (user === null) {
    return Promise.resolve("");
  }
  return new Promise((resolve, reject) => {
    user.getSession((err: Error, result: CognitoUserSession | null) => {
      if (err !== null || result === null) {
        return reject(err);
      }
      return resolve(result.getAccessToken().getJwtToken());
    });
  });
};

export const newCognitoUser = (email: string) => {
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool: getUserPool(),
    Storage: getCookieStorage(),
  });
};
