import { MessageOutlined, SendOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { resendConfirmationCode } from "../../../../utils/account/confirm";

const hasHubspot = () => {
  try {
    return window.HubSpotConversations.widget.status().loaded;
  } catch (e) {
    return false;
  }
};

interface ResendCodeProps {
  open: boolean;
  email: string;
  onClose: () => void;
}
const ResendCode = ({ open, email, onClose }: ResendCodeProps) => {
  const { t } = useTranslation();
  const [resent, setResent] = useState(false);
  const [resending, setResending] = useState(false);
  const [resendFailed, setResendFailed] = useState(false);

  useEffect(() => {
    setResent(false);
    setResending(false);
    setResendFailed(false);
  }, [open]);

  const onClickResend = async () => {
    try {
      setResending(true);
      await resendConfirmationCode(email);
      setResent(true);
    } catch (e) {
      setResendFailed(true);
    } finally {
      setResending(false);
    }
  };

  return (
    <Dialog open={open} disableAutoFocus onClose={onClose} scroll="paper">
      <DialogContent>
        <Typography variant="h6" mb={1}>
          {t("dialog.resendVerificationCode.title")}
        </Typography>
        <Typography variant="body1" mb={2}>
          {t("dialog.resendVerificationCode.description")}
        </Typography>
        <Stack alignItems="center">
          <Box mb={2}>
            <Typography variant="h6">{email}</Typography>
          </Box>
          <Box mb={2}>
            {resendFailed && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {t("dialog.resendVerificationCode.feedback.error")}
              </Alert>
            )}
          </Box>
          <Stack direction="row" spacing={2} mb={2}>
            <LoadingButton
              variant="outlined"
              endIcon={<SendOutlined />}
              loadingPosition="end"
              loading={resending}
              onClick={onClickResend}
            >
              {t("dialog.resendVerificationCode.button.send.label")}
            </LoadingButton>
            {resendFailed && hasHubspot() && (
              <Button
                variant="outlined"
                endIcon={<MessageOutlined />}
                onClick={() => window.HubSpotConversations.widget.open()}
              >
                {t("dialog.resendVerificationCode.button.support.label")}
              </Button>
            )}
          </Stack>
          {resent && (
            <Alert severity="success" sx={{ width: "100%" }}>
              <Trans i18nKey="dialog.resendVerificationCode.feedback.sent">
                We've sent a new verification code to{" "}
                <strong>{{ email }}</strong>
              </Trans>
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button onClick={onClose} variant="text">
          {t("dialog.resendVerificationCode.button.close.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResendCode;
