import styled from "@emotion/styled";
import { DevTool } from "@hookform/devtools";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Terms from "../../components/terms/Terms";
import signup from "../../utils/account/signup";

const CompanyName = styled.span`
  font-weight: 500;
`;

interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  signUpComment: string;
  termsAccepted: boolean;
}

interface ContactDetailsProps {
  signupInformation: SignupInformation;
  language: Language;
  productName: string;
  productId: string;
  onBack: () => void;
  onSubmit: (contactDetails: ContactDetails) => void;
}

const ContactDetails = ({
  signupInformation,
  language,
  productName,
  productId,
  onBack,
  onSubmit,
}: ContactDetailsProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, errors },
  } = useForm<FormFields>({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      signUpComment: "",
      termsAccepted: false,
    },
  });
  const [submitError, setSubmitError] = useState<null | string>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const submit: SubmitHandler<FormFields> = (data) => {
    setFormSubmitting(true);

    const { firstName, lastName, email, password, signUpComment } = data;

    signup(
      {
        ...signupInformation,
        firstName,
        lastName,
        email,
        password,
        signUpComment,
      },
      language,
      productId,
      (error: Error | undefined, result: ISignUpResult | undefined) => {
        setFormSubmitting(false);
        if (result) {
          onSubmit({ firstName, lastName, email, password });
        }
        if (error) {
          setSubmitError(error.message);
        }
      }
    );
  };

  const submitDisabled = !isValid;

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(submit)}>
      <Stack alignItems="center" mb={4}>
        <Stack direction="row" mb={2}>
          <Typography variant="body1">
            <Trans i18nKey="step.contactDetails.accountTitle">
              You are creating a <strong>{{ productName }}</strong> account for:
            </Trans>
          </Typography>
        </Stack>
        <Typography variant="h6">
          <CompanyName>{signupInformation.companyName}</CompanyName>
        </Typography>
      </Stack>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {t("step.contactDetails.yourInfo.title")}
      </Typography>
      <Grid2 container rowSpacing={2} columnSpacing={4} mb={2}>
        <Grid2 xs={12} md={6}>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label={t("step.contactDetails.firstName.label")}
                fullWidth
                autoFocus
                autoComplete="given-name"
                error={!!errors.firstName}
                {...field}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Controller
            control={control}
            name="lastName"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label={t("step.contactDetails.lastName.label")}
                fullWidth
                required
                error={!!errors.lastName}
                autoComplete="family-name"
                {...field}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Controller
            control={control}
            name="email"
            rules={{ required: true, pattern: /\S+@\S+\.\S+/ }}
            render={({ field }) => (
              <TextField
                required
                label={t("step.contactDetails.email.label")}
                helperText={t("step.contactDetails.email.subLabel")}
                fullWidth
                error={!!errors.email}
                autoComplete="email"
                type="email"
                {...field}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Controller
            control={control}
            name="password"
            rules={{
              required: true,
              pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,30}/,
            }}
            render={({ field }) => (
              <TextField
                required
                label={t("step.contactDetails.password.label")}
                helperText={t("step.contactDetails.password.subLabel")}
                fullWidth
                error={!!errors.password}
                autoComplete="new-password"
                {...field}
                InputProps={{
                  type: showPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid2>
      </Grid2>
      {submitError && (
        <Box mb={2}>
          <Alert severity="error">{submitError}</Alert>
        </Box>
      )}
      <Stack direction="row" alignItems="center" mb={1}>
        <Controller
          control={control}
          name="termsAccepted"
          rules={{ required: true }}
          render={({ field }) => (
            <Checkbox
              color="primary"
              checked={field.value}
              {...field}
              inputProps={{
                "aria-label":
                  `${t("step.contactDetails.terms.label.partA")} ${t(
                    "step.contactDetails.terms.label.partB"
                  )}` ?? "",
              }}
            />
          )}
        />
        <Typography>{t("step.contactDetails.terms.label.partA")}</Typography>
        &nbsp;
        <Link onClick={() => setShowTerms(true)}>
          {t("step.contactDetails.terms.label.partB")}
        </Link>
        <Terms
          open={showTerms}
          language={language}
          productId={productId}
          onClose={() => setShowTerms(false)}
          onAccept={() => {
            setValue("termsAccepted", true, {
              shouldDirty: true,
              shouldValidate: true,
            });
            setShowTerms(false);
          }}
        />
      </Stack>
      <Controller
        control={control}
        name="signUpComment"
        render={({ field }) => (
          <TextField
            label={t("step.contactDetails.signUpComment.label")}
            fullWidth
            sx={{ mb: 4 }}
            {...field}
          />
        )}
      />
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Button variant="text" onClick={onBack} disabled={formSubmitting}>
          {t("button.back")}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={submitDisabled}
          loading={formSubmitting}
        >
          {t("button.next")}
        </LoadingButton>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};

export default ContactDetails;
