import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/index.css";
import initi18n from "./i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import roaringTheme from "./assets/themes/roaring";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

initi18n();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const validateEnvVars = () => {
  const requiredVars = [
    "REACT_APP_COGNITO_USER_POOL_ID",
    "REACT_APP_COGNITO_CLIENT_ID",
    "REACT_APP_COOKIE_STORAGE_DOMAIN",
    "REACT_APP_COOKIE_STORAGE_SECURE",
    "REACT_APP_BACKEND_URL",
    "REACT_APP_STRIPE_KEY",
    "REACT_APP_APP_URL",
    "REACT_APP_HUBSPOT_ID",
    "REACT_APP_GOOGLE_TAG_MANAGER_ID",
    "REACT_APP_AUTOMATICALLY_REDIRECT_LOGGED_IN_USERS",
    "REACT_APP_API_KEY_REDIRECT_IDS",
    "REACT_APP_API_KEY_PAGE_URL",
    "REACT_APP_WEB_SUITE_URL",
    "REACT_APP_INTEGRATION_SUITE_URL",
  ];
  const missingVars = requiredVars.reduce<Array<string>>((missing, name) => {
    if (process.env[name] === undefined || process.env[name]?.length === 0) {
      return [...missing, name];
    }
    return missing;
  }, []);
  if (missingVars.length > 0) {
    root.render(
      <React.StrictMode>
        <pre>Missing env vars {JSON.stringify(missingVars, null, 2)}</pre>
      </React.StrictMode>
    );
    throw new Error("missing env vars");
  }
};

validateEnvVars();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5,
    },
  },
});

const theme = createTheme(roaringTheme);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <App />
        </CssBaseline>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
