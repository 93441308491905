import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import config from "./config";

const options: InitOptions = {
  backend: {
    loadPath: `${config.ASSETS_HOST}/locales/{{lng}}/{{ns}}.json`,
    addPath: `${
      process.env.REACT_APP_BACKEND_URL ?? ""
    }/locales/{{lng}}/{{ns}}`,
    allowMultiLoading: false,
    parsePayload: (_: string, key: string) => ({ key }),
    crossDomain: true,
    withCredentials: false,
    reloadInterval: false,
    queryStringParams: {
      v: `${new Date().getHours() + new Date().getMinutes()}`,
    },
  },
  debug: true,
  ns: "onboarding",
  defaultNS: "onboarding",
  fallbackLng: {
    "en-US": ["en"],
    en: ["en"],
    sv: ["sv"],
    no: ["no"],
    da: ["da"],
  },
  keySeparator: false,
  load: "languageOnly",
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
};

const initi18n = () => {
  const params = new URLSearchParams(window.location.search);
  const lng = params.get("hl") as string;
  return i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({ ...options, lng });
};

export default initi18n;
