interface Options {
  status?: ProductStatus;
  language: Language;
  productId: string | null;
}
/**
 * Get available products
 */
const getProduct = async ({
  status,
  language,
  productId,
}: Options): Promise<APIResponseGetProduct | null> => {
  const queryParams = status
    ? `?hl=${language}&status=${status}`
    : `?hl=${language}`;
  const url = `${process.env.REACT_APP_BACKEND_URL}/product/${productId}${queryParams}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
  if (!response.ok) {
    return null;
  }
  return await response.json();
};

export default getProduct;
