import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import confirm from "../../utils/account/confirm";
import addGTMEvent from "../../utils/gtm";
import CodeInput from "./components/code-input/CodeInput";
import ResendCode from "./components/resend-code/ResendCode";

const CompanyName = styled.span`
  font-weight: 500;
`;

/**
 * Log to GTM when subscrition is in sandbox
 */
const logSandboxAccount = () => {
  addGTMEvent({
    event: "sandboxAccount",
  });
};

interface AccountVerificationProps {
  companyName: string;
  email: string;
  password: string;
  productName: string;
  onSubmit: () => void;
}

const AccountVerification = ({
  companyName,
  email,
  password,
  productName,
  onSubmit,
}: AccountVerificationProps) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState("");
  const [resendCodeDialogOpen, setResendCodeDialogOpen] = useState(false);
  // Code does match required format
  const [verificationCodeInputValid, setVerificationCodeInputValid] =
    useState(false);
  // Code does not match required format
  const [verificationCodeInputIncorrect, setVerificationCodeIncorrect] =
    useState(false);
  // Backend says code is not valid
  const [verificationCodeInvalid, setVerificationCodeInvalid] = useState(false);
  const [verificationCodeInvalidMessage, setVerificationCodeInvalidMessage] =
    useState<undefined | string>();
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const verificationCodeRef = useRef<HTMLInputElement>(null);

  const onFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    submitForm();
  };
  const submitForm = async () => {
    setFormSubmitting(true);
    const verificationCode = verificationCodeRef.current?.value ?? "";
    try {
      await confirm(email, verificationCode, password);
      logSandboxAccount();
      onSubmit();
    } catch (e: any) {
      setVerificationCodeInvalid(true);
      setVerificationCodeInvalidMessage(e.code);
    } finally {
      setFormSubmitting(false);
    }
  };

  const onBlurTextField = (ref: React.RefObject<HTMLInputElement>) => () => {
    if (!ref.current) {
      return;
    }
    const valid = ref.current.checkValidity();
    const value = ref.current.value;

    if (value.length === 0) {
      setVerificationCodeIncorrect(false);
      return;
    }
    setVerificationCodeIncorrect(!valid);
    setVerificationCodeInvalidMessage("invalid");
  };

  const onChangeVerificationCodeField =
    (ref: React.RefObject<HTMLInputElement>) => () => {
      if (!ref.current) {
        return;
      }
      const valid = ref.current.checkValidity();
      const value = ref.current.value;
      setVerificationCodeInputValid(valid);
      setVerificationCode(value);
      if (valid && value.length === 6) {
        submitForm();
      }
    };

  const onClickResendVerificationCode = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setResendCodeDialogOpen(true);
  };

  const submitDisabled = !verificationCodeInputValid;

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={onFormSubmit}>
        <Stack alignItems="center" mb={4}>
          <Box mb={2}>
            <Typography variant="body1">
              <Trans i18nKey="step.accountVerification.title">
                You are creating a <strong>{{ productName }}</strong> account
                for:
              </Trans>
            </Typography>
          </Box>
          <Typography variant="h6">
            <CompanyName>{companyName}</CompanyName>
          </Typography>
        </Stack>
        <Box mb={4}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {t("step.accountVerification.subTitle")}
          </Typography>
          <Typography>
            <Trans
              ns="onboarding"
              i18nKey="step.accountVerification.description"
            >
              We've sent a verification code to <strong>{{ email }}</strong>.
              Enter the six-digit code from the e-mail in the field below.
            </Trans>
          </Typography>
          <Box mb={1}>
            <Typography variant="caption" mb={2}>
              ({t("step.accountVerification.notSeeingEmailHint.partA")}&nbsp;
              <Link variant="caption" onClick={onClickResendVerificationCode}>
                {t("step.accountVerification.notSeeingEmailHint.partB")}
              </Link>
              )
            </Typography>
          </Box>
          <Stack alignItems="center">
            <CodeInput
              ref={verificationCodeRef}
              code={verificationCode}
              error={verificationCodeInvalid || verificationCodeInputIncorrect}
              label={t("step.accountVerification.verificationCode.label")}
              errorMessage={t(
                `step.accountVerification.verificationCode.error.${verificationCodeInvalidMessage}`
              )}
              onChange={onChangeVerificationCodeField(verificationCodeRef)}
              onBlur={onBlurTextField(verificationCodeRef)}
            />
          </Stack>
        </Box>
        <Stack direction="row" display="flex" justifyContent="flex-end">
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={submitDisabled}
            loading={formSubmitting}
          >
            {t("button.next")}
          </LoadingButton>
        </Stack>
      </form>
      <ResendCode
        open={resendCodeDialogOpen}
        email={email}
        onClose={() => setResendCodeDialogOpen(false)}
      />
    </>
  );
};

export default AccountVerification;
