/**
 * Configuration holding app specific settings
 */

const config = {
  ALLOWED_COUNTRIES: ["se", "no", "dk", "fi", "es"],
  ASSETS_HOST: "https://assets.roaring.io",
};

export default config;
