import { Link, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const RedirectToApp = () => {
  const { t } = useTranslation();
  const appUrl = process.env.REACT_APP_APP_URL;
  const automaticallyRedirect =
    process.env.REACT_APP_AUTOMATICALLY_REDIRECT_LOGGED_IN_USERS === "true";
  if (appUrl && automaticallyRedirect) {
    setTimeout(() => {
      window.location.href = appUrl;
    }, 5 * 1000);
  }

  return (
    <Stack alignItems="center">
      <Stack direction="row" mb={3}>
        <Typography variant="body1">{t("step.redirectToApp.title")}</Typography>
      </Stack>
      <Typography variant="body2">
        <Trans ns="onboarding" i18nKey="step.redirectToApp.description">
          Click <Link href={appUrl}>here</Link> to go the app or wait to be
          redirected automatically.
        </Trans>
      </Typography>
    </Stack>
  );
};

export default RedirectToApp;
