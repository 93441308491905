import { ThemeOptions } from "@mui/material";

const fonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Segoe UI Variable Text",
  "system-ui",
  "ui-sans-serif",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
].join(",");

export const colors = {
  primary: "rgba(18, 130, 115, 1)",
  primary20: "rgba(18, 130, 115, 0.2)",
  topBarLogoColor: "#1B5048",
  logo: "#1B5048",
  green: "#1B5048",
  darkGreen: "#006666",
  surfaceHdrColor: "#005548",
  darkestGreen: "#023C34",
  heroBackground: "#023C34F5",
  cardHeaderColor: "#023C34E0",
  primaryBrandColor: "#1B5048",
  linkColor: "#0E7FAA ",
  blue: "#334288",
  gray: "rgba(0,0,0, 0.7)",
  darkGray: "#3d3d3d",
  lightGray: "rgba(0, 0, 0, 0.26)",
  borderColor: "rgba(0, 0, 0, 0.2)",
  icon: "#272727",
  border: "#E4E4E4",
  orange: "#F2832C",
  red: "#DA2128",
  yellow: "#F2CA41",
  pink: "#C13F81",
  kyc: "#FFF8F2",
  backgroundColor: "#F7FAFD",
  pepSanctionsBackgroundColor: "#FAFAFA",
  hoverColor: "#1B50481A",
  hoverColor2: "#1b50480d",
  selectedGreenColor: "rgba(18, 130, 115, 0.08)",
};

const BORDER_RADIUS = 8;

const theme: ThemeOptions = {
  typography: {
    fontFamily: fonts,
    h4: {
      fontWeight: 500,
      color: colors.icon,
    },
    h5: {
      color: colors.icon,
      fontWeight: 600,
    },
    h6: {
      color: colors.gray,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      color: colors.gray,
    },
    body1: {
      color: colors.gray,
    },
    caption: {
      color: colors.gray,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.8125rem",
      lineHeight: 1.43,
      letterSpacing: "0.00938em",
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  palette: {
    primary: {
      main: colors.green,
      contrastText: "#fff",
    },
    secondary: {
      main: colors.blue,
      contrastText: "#fff",
    },
    error: {
      main: colors.red,
      contrastText: "#fff",
    },
    warning: {
      main: colors.orange,
      contrastText: "#fff",
    },
    text: {
      primary: colors.gray,
      disabled: colors.lightGray,
    },
  },
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 0 0 16px",
          "&:hover": {
            backgroundColor: colors.hoverColor,
          },
        },
        expandIconWrapper: {
          padding: 12,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterNextDelay: 200,
        enterDelay: 1000,
        arrow: true,
        disableInteractive: true,
        TransitionProps: { timeout: { enter: 0, exit: 0 } },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: colors.icon,
        },
        inset: {
          paddingLeft: 42,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 42,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "calc(100% - 16px)",
          margin: 8,
        },
        paperScrollBody: {
          maxWidth: 600,
        },
        paperScrollPaper: {
          maxWidth: 600,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: false,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            color: colors.icon,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "large",
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          whiteSpace: "nowrap",
        },
        containedPrimary: {
          color: "#ffffff",
        },
        containedSecondary: {
          color: "#fff",
          backgroundColor: colors.pink,
          "&:hover": {
            backgroundColor: colors.pink,
          },
        },
        sizeSmall: {
          height: 36,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.43,
          marginTop: 0,
          marginBottom: "0.15em",
          letterSpacing: "0.00938em",
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#eff4f3",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "medium",
        margin: "dense",
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
          "&.verificationCodeInput .MuiInputLabel-root:not(.MuiInputLabel-shrink)":
            {
              transform: "translate(14px, 21px) scale(1)",
            },
          "&.verificationCodeInput .MuiInputBase-input": {
            fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
            fontWeight: 600,
            fontSize: 32,
            letterSpacing: 6,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        square: true,
        disableGutters: true,
        elevation: 0,
      },
      styleOverrides: {
        root: {
          "&.Mui-expanded .MuiAccordionSummary-root": {
            backgroundColor: colors.selectedGreenColor,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          transition: "none !important",
        },
        list: {
          maxHeight: 300,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          color: colors.linkColor,
          fontFamily: fonts,
          fontWeight: 500,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        button: {
          textAlign: "left",
          display: "block",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          padding: 0,
        },
        outlinedInfo: {
          "& .MuiAlert-icon": {
            color: colors.lightGray,
          },
          backgroundColor: "#fff",
          color: colors.gray,
          border: `1px solid ${colors.lightGray}`,
        },
        filledWarning: {
          "& .MuiAlert-icon": {
            color: "#F2832C",
          },
          color: "#7F3E0C",
          backgroundColor: "#fff2d3",
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          marginBottom: 72,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          margin: "0 16px 16px",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child .MuiTableCell-body": {
            borderBottomWidth: 0,
          },
        },
        head: {
          backgroundColor: "transparent",
          height: 50,
        },
        footer: {
          borderWidth: "1px 0 0 0",
          borderStyle: "solid",
          borderColor: colors.border,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderWidth: 0,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          margin: "32px 0",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 7,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          borderColor: colors.border,
        },
        body: {
          color: colors.gray,
          verticalAlign: "top",
        },
        sizeSmall: {
          padding: "6px 8px",
        },
        head: {
          whiteSpace: "nowrap",
          paddingTop: 0,
          paddingBottom: 0,
          color: colors.icon,
          fontSize: "0.813rem",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          border: `1px solid rgba(0, 0, 0, 0.26)`,
          backgroundColor: "#fff",
          color: colors.gray,
          padding: "0 16px",
        },
        message: {
          padding: 0,
        },
        action: {
          top: 0,
          right: 0,
          position: "absolute",
          margin: "0px !important",
          padding: "0px !important",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          "&$disabled": {
            backgroundColor: "#bdbdbd",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        avatar: {
          backgroundColor: "transparent !important",
        },
        root: {
          borderColor: colors.border,
          "& $avatarColorPrimary": {
            backgroundColor: "transparent",
          },
          "& $avatarColorSecondary": {
            backgroundColor: "transparent",
          },
        },
        colorSecondary: {
          backgroundColor: colors.gray,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.gray,
        },
        colorPrimary: {
          color: colors.lightGray,
        },
        colorSecondary: {
          color: "#fff",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: "40px",
          fontSize: "0.75rem",
          color: colors.icon,
          textTransform: "uppercase",
          borderWidth: "0 0 1px 0",
          borderStyle: "solid",
          borderColor: colors.border,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          right: "-3px !important",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: colors.lightGray,
        },
        text: {
          fill: "#fff",
        },
      },
    },
  },
};

export default theme;
