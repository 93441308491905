import { useEffect } from "react";

declare global {
  interface Window {
    HubSpotConversations: {
      widget: {
        load: () => {};
        open: () => {};
        remove: () => {};
        status: () => {
          loaded: boolean;
          pending: boolean;
        };
      };
    };
    hsConversationsOnReady: any;
    hsConversationsSettings: {
      loadImmediately: boolean;
      disableAttachment: boolean;
      enableWidgetCookieBanner: boolean;
    };
  }
}

interface HubspotProps {
  /**
   * Account id
   */
  id?: string;
}

const Hubspot = ({ id }: HubspotProps) => {
  const onConversationsAPIReady = () => {
    window.HubSpotConversations.widget.load();
  };

  /**
   * Inject Hubspot script tag
   */
  useEffect(() => {
    if (!id) {
      return;
    }

    const tagId = "hubspot";

    // Prevent loading multiple instances
    const existingScript = window.document.getElementById(
      tagId
    ) as HTMLScriptElement;
    if (existingScript) {
      return;
    }

    const script = document.createElement("script");
    script.id = tagId;
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${id}.js`;

    // Add new script tag next to existing
    const sibling = document.getElementsByTagName("script")[0];
    if (!sibling.parentNode) {
      console.error(
        "failed finding parent node to insert hubspot script tag into"
      );
      return;
    }
    sibling.parentNode.insertBefore(script, sibling);
  }, [id]);

  /**
   * Instruct Hubspot to how to load
   */
  useEffect(() => {
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }

    window.hsConversationsSettings = {
      loadImmediately: false,
      disableAttachment: true,
      enableWidgetCookieBanner: false,
    };

    return () => {
      if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        window.HubSpotConversations.widget.remove();
      }
    };
  }, []);

  return null;
};

export default Hubspot;
