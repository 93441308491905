import { DevTool } from "@hookform/devtools";
import { LoadingButton } from "@mui/lab";
import {
  Typography,
  FormControl,
  TextField,
  Stack,
  Alert,
  Collapse,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import CountrySelector from "../../components/country-selector/CountrySelector";
import getCompanyOverview from "../../network/get-company-overview";

interface CompanyInformationProps {
  language: Language;
  country: string;
  registrationNumber: string;
  productName: string;
  onSubmit: (information: CompanyInformation) => void;
}

const CompanyInformation = ({
  language,
  country,
  registrationNumber,
  productName,
  onSubmit,
}: CompanyInformationProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    getFieldState,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<CompanyInformation>({
    defaultValues: {
      companyId: registrationNumber,
      companyName: "",
      zip: "",
      streetAddress: "",
      city: "",
      country,
      vat: "",
      vatStatus: "EMPTY",
      vatType: "SEVAT",
    },
  });
  const [submitStatus, setSubmitStatus] = useState<
    CompanySubmitStatus | undefined
  >(undefined);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  const submit: SubmitHandler<CompanyInformation> = async (data) => {
    setFormSubmitting(true);

    /**
     * Form has been submitted once already, with an incomplete result.
     * Company information has been manually entered instead of using
     * information from backend. Just pass it on.
     */
    if (submitStatus === "INCOMPLETE") {
      onSubmit(data);
      setFormSubmitting(false);
      return;
    }

    const information = await getCompanyOverview(data.country, data.companyId);

    const { status, city, companyName, zip, streetAddress } =
      information.properties;
    setSubmitStatus(status);
    setValue("companyName", companyName);
    setValue("streetAddress", streetAddress);
    setValue("zip", zip);
    setValue("city", city);

    if (status === "OK") {
      onSubmit(information.properties);
    }
    setFormSubmitting(false);
  };

  /**
   * Check validity when rendering.
   * The field is valid when going back from contact details view.
   */
  useEffect(() => {
    setValue("companyId", registrationNumber);
  }, [setValue, registrationNumber]);

  const submitButtonEnabled = isValid;

  const [companyName, streetAddress, city, zip, vat] = watch([
    "companyName",
    "streetAddress",
    "city",
    "zip",
    "vat",
  ]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(submit)}>
      <Stack alignItems="center" mb={4}>
        <Stack direction="row" mb={2}>
          <Typography variant="body1">
            <Trans i18nKey="step.companyInformation.accountTitle">
              You are creating a <strong>{{ productName }}</strong> account
            </Trans>
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="subtitle1" mb={4}>
        {t("step.companyInformation.title")}
      </Typography>
      <Grid2 container rowSpacing={2} columnSpacing={4} mb={4}>
        <Grid2 xs={12} md={6} display="flex" alignItems="center">
          <FormControl fullWidth>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <CountrySelector language={language} {...field} />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Controller
            control={control}
            name="companyId"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label={t("step.companyInformation.registrationNumberLabel")}
                fullWidth
                error={!!errors.companyId}
                {...field}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12}>
          {submitStatus === "INVALID" && (
            <Alert severity="info">
              {t("step.companyInformation.feedback.companyNotFound")}
            </Alert>
          )}
        </Grid2>
      </Grid2>
      <Collapse in={submitStatus === "INCOMPLETE"}>
        <Typography variant="subtitle1" mb={0}>
          {t("step.companyInformation.additionalInformation.title")}
        </Typography>
        <Grid2 container rowSpacing={2} columnSpacing={4} mb={4}>
          <Grid2 xs={12} md={6} display="flex" alignItems="center">
            <Controller
              control={control}
              name="companyName"
              rules={{ required: submitStatus === "INCOMPLETE" }}
              render={({ field }) => (
                <TextField
                  label={t("step.companyInformation.companyName.label")}
                  disabled={
                    !(
                      companyName.length === 0 ||
                      getFieldState("companyName").isDirty
                    )
                  }
                  fullWidth
                  error={!!errors.companyName}
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Controller
              control={control}
              name="streetAddress"
              rules={{ required: submitStatus === "INCOMPLETE" }}
              render={({ field }) => (
                <TextField
                  label={t("step.companyInformation.streetAddress.label")}
                  fullWidth
                  disabled={
                    !(
                      streetAddress.length === 0 ||
                      getFieldState("streetAddress").isDirty
                    )
                  }
                  error={!!errors.streetAddress}
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Controller
              control={control}
              name="city"
              rules={{ required: submitStatus === "INCOMPLETE" }}
              render={({ field }) => (
                <TextField
                  label={t("step.companyInformation.city.label")}
                  fullWidth
                  disabled={
                    !(city.length === 0 || getFieldState("city").isDirty)
                  }
                  error={!!errors.city}
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Controller
              control={control}
              name="zip"
              rules={{ required: submitStatus === "INCOMPLETE" }}
              render={({ field }) => (
                <TextField
                  label={t("step.companyInformation.zip.label")}
                  fullWidth
                  disabled={!(zip.length === 0 || getFieldState("zip").isDirty)}
                  error={!!errors.zip}
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Controller
              control={control}
              name="vat"
              rules={{ required: submitStatus === "INCOMPLETE" }}
              render={({ field }) => (
                <TextField
                  label={t("step.companyInformation.vat.label")}
                  fullWidth
                  disabled={!(vat.length === 0 || getFieldState("vat").isDirty)}
                  error={!!errors.vat}
                  {...field}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </Collapse>
      <Stack direction="row" display="flex" justifyContent="flex-end">
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!submitButtonEnabled}
          loading={formSubmitting}
        >
          {t("button.next")}
        </LoadingButton>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};

export default CompanyInformation;
