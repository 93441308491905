import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserToken } from "../../utils/account/common";
import CardInput from "./components/CardInput/CardInput";
import * as stripeJs from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { CreditCard, InfoOutlined, Receipt } from "@mui/icons-material";
import updateSubscription from "../../network/update-subscription";
import getOrganizations from "../../network/get-organizations";
import getCompanyOverview from "../../network/get-company-overview";
import addGTMEvent from "../../utils/gtm";

type PaymentType = "creditCard" | "invoice";

interface StripeObjects {
  stripe: stripeJs.Stripe;
  elements: stripeJs.StripeElements;
}

interface PaymentMethodProps {
  language: Language;
  signupInformation: SignupInformation;
  onSubmit: () => void;
}

/**
 * Log to GTM when subscrition is in production
 */
const logProductionAccount = (
  subscription: Subscription,
  organizationId: string
) => {
  if (subscription.status === "ACTIVE") {
    addGTMEvent({
      event: "productionAccount",
      orgId: organizationId,
    });
  }
};

const PaymentMethod = ({
  language,
  signupInformation,
  onSubmit,
}: PaymentMethodProps) => {
  const { t } = useTranslation();
  const [paymentType, setPaymentType] = useState<PaymentType>("invoice");
  const referenceInputRef = useRef<HTMLInputElement>(null);
  const [verificationChecked, setVerificationChecked] =
    useState<boolean>(false);
  const [referenceValid, setReferenceValid] = useState(false);
  const [referenceHasReceivedInput, setReferenceHasReceivedInput] =
    useState(false);
  const [cardValid, setCardValid] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [stripeObjects, setStripeObjects] = useState<
    undefined | StripeObjects
  >();
  const { data: organization } = useQuery({
    queryKey: ["organization"],
    queryFn: async () => {
      const userToken = (await getUserToken()) as string;
      return getOrganizations(userToken);
    },
  });
  const { data: companyOverview, isLoading: companyOverviewLoading } = useQuery(
    {
      queryKey: ["company-overview", signupInformation.companyId],
      queryFn: () =>
        getCompanyOverview(
          signupInformation.country,
          signupInformation.companyId
        ),
    }
  );

  /**
   * Correct possible invalid payment method choice when billing options
   * have been loaded.
   */
  useEffect(() => {
    const billingOptions = companyOverview?.properties.billingOptions ?? [];
    if (companyOverviewLoading) {
      return;
    }
    if (paymentType === "invoice" && !billingOptions.includes("send_invoice")) {
      setPaymentType("creditCard");
    }
    if (
      paymentType === "creditCard" &&
      !billingOptions.includes("charge_automatically")
    ) {
      setPaymentType("invoice");
    }
  }, [companyOverview, paymentType, setPaymentType, companyOverviewLoading]);

  const onFormSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!organization) {
      return null;
    }
    setFormSubmitting(true);
    try {
      const organizationId = organization.properties.elements[0].properties.id;
      const reference = referenceInputRef.current?.value ?? "";
      const userToken = (await getUserToken()) as string;

      if (paymentType === "invoice") {
        const subscription = await updateSubscription(
          userToken,
          organizationId,
          {
            reference,
            billing: "send_invoice",
          }
        );
        logProductionAccount(subscription.properties, organizationId);
      } else {
        if (stripeObjects) {
          const { stripe, elements } = stripeObjects;
          const cardElement = elements.getElement(CardElement);
          if (cardElement) {
            const { token, error } = await stripe.createToken(cardElement);
            if (error) {
              throw error;
            }
            if (!token) {
              throw new Error("failed getting card token");
            }
            const subscription = await updateSubscription(
              userToken,
              organizationId,
              {
                reference,
                billing: "charge_automatically",
                cardToken: token.id,
              }
            );
            logProductionAccount(subscription.properties, organizationId);
          }
        }
      }

      onSubmit();
      setFormSubmitError(false);
    } catch (e: any) {
      console.error(e);
      setFormSubmitError(true);
    } finally {
      setFormSubmitting(false);
    }
  };

  const onChangeReference = (ref: React.RefObject<HTMLInputElement>) => () => {
    if (!ref.current) {
      return;
    }
    const valid = ref.current.checkValidity();
    setReferenceValid(valid);
    setReferenceHasReceivedInput(true);
  };

  const onCardChange = (e: CardInputChangeEvent) => setCardValid(e.complete);

  /**
   * Set fields ok on render in case some fields already have values
   */
  useEffect(() => {
    setReferenceValid((referenceInputRef.current?.value ?? "").length > 0);
    setReferenceHasReceivedInput(
      (referenceInputRef.current?.value ?? "").length > 0
    );
  }, []);

  const submitDisabled =
    (paymentType === "creditCard" && !cardValid) ||
    !referenceValid ||
    !verificationChecked;

  const billingOptions = companyOverview?.properties.billingOptions ?? [];

  return (
    <form autoComplete="off" noValidate onSubmit={onFormSubmit}>
      <Stack alignItems="center" mb={4}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {t("step.paymentMethod.title")}
        </Typography>
      </Stack>
      <Stack alignItems="center" mb={3}>
        <Tabs
          TabIndicatorProps={{ sx: { height: 4 } }}
          value={paymentType}
          onChange={(_: React.SyntheticEvent, value: PaymentType) =>
            setPaymentType(value)
          }
        >
          <Tab
            sx={{ minHeight: 0 }}
            icon={<Receipt />}
            iconPosition="start"
            aria-label="Invoice"
            label={t("step.paymentMethod.invoice.label")}
            value="invoice"
            disabled={!billingOptions.includes("send_invoice")}
          />
          <Tab
            sx={{ minHeight: 0 }}
            icon={<CreditCard />}
            iconPosition="start"
            aria-label="Credit card"
            label={t("step.paymentMethod.creditCard.label")}
            value="creditCard"
            disabled={!billingOptions.includes("charge_automatically")}
          />
        </Tabs>
      </Stack>
      <Grid2 container rowSpacing={2} columnSpacing={4}>
        <Grid2 xs={12} md={4}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t("step.paymentMethod.field.company.label")}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={8}>
          <Typography variant="body2">
            {signupInformation.companyName}
            <br />
            {signupInformation.streetAddress}
            <br />
            {signupInformation.zip} {signupInformation.city}
          </Typography>
        </Grid2>
        {paymentType === "creditCard" && (
          <>
            <Grid2 xs={12} md={4} display="flex" alignItems="center">
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {t("step.paymentMethod.field.creditCard.label")}
              </Typography>
            </Grid2>
            <Grid2 xs={12} md={8}>
              <CardInput
                language={language}
                onLoad={(
                  stripe: stripeJs.Stripe | null,
                  elements: stripeJs.StripeElements | null
                ) => {
                  if (!stripeObjects && stripe && elements) {
                    setStripeObjects({ stripe, elements });
                  }
                }}
                onChange={onCardChange}
              />
            </Grid2>
          </>
        )}
        <Grid2 xs={12} md={4} display="flex" alignItems="center">
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t("step.paymentMethod.field.reference.label")}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={8}>
          <TextField
            required
            autoFocus={paymentType === "invoice"}
            label={t("step.paymentMethod.field.reference.placeholder")}
            fullWidth
            defaultValue={`${signupInformation.firstName} ${signupInformation.lastName}`}
            error={referenceHasReceivedInput && !referenceValid}
            inputRef={referenceInputRef}
            onChange={onChangeReference(referenceInputRef)}
          />
        </Grid2>
        <Grid2 xs={12} md={4} display="flex" alignItems="center">
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t("step.paymentMethod.field.invoiceRecipient.label")}
            <Tooltip
              enterDelay={0}
              arrow
              disableFocusListener
              title={t("step.paymentMethod.field.invoiceRecipient.tooltip")}
            >
              <IconButton>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={8} display="flex" alignItems="center">
          <Typography variant="body2">{signupInformation.email}</Typography>
        </Grid2>
        <Grid2 xs={1} display="flex">
          <Checkbox
            checked={verificationChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setVerificationChecked(e.target.checked)
            }
          />
        </Grid2>
        <Grid2 xs={11}>
          <Typography
            variant="body2"
            sx={{ cursor: "pointer" }}
            onClick={() => setVerificationChecked(!verificationChecked)}
          >
            {t("step.paymentMethod.field.verification.label")}{" "}
          </Typography>
        </Grid2>
      </Grid2>
      {formSubmitError && (
        <Box mb={2} mt={2}>
          <Alert severity="error">
            {t("step.paymentMethod.feedback.error.")}
          </Alert>
        </Box>
      )}
      <Stack direction="row" display="flex" mt={4} justifyContent="flex-end">
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={submitDisabled}
          loading={formSubmitting}
        >
          {t("button.next")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default PaymentMethod;
