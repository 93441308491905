export const generateAppUrl = (product?: Product): string => {
  if (!product) {
    return process.env.REACT_APP_APP_URL ?? "";
  }
  if (!!product.details.redirectUrl) {
    return product.details.redirectUrl;
  }
  const apiKeyRedirectIDsRaw = process.env.REACT_APP_API_KEY_REDIRECT_IDS ?? "";
  const apiKeyRedirectIDs = apiKeyRedirectIDsRaw
    .split(",")
    .map((id) => id.trim());
  const redirectToAPIKeys = apiKeyRedirectIDs.includes(product.id);
  if (redirectToAPIKeys) {
    return process.env.REACT_APP_API_KEY_PAGE_URL ?? "";
  }
  const tier = product.tier;
  if (tier === "NONE") {
    if (product.roleProducts?.includes("web")) {
      return process.env.REACT_APP_WEB_SUITE_URL ?? "";
    }
    return process.env.REACT_APP_INTEGRATION_SUITE_URL ?? "";
  }
  if (tier === "WEB_ONLY") {
    return process.env.REACT_APP_WEB_SUITE_URL ?? "";
  }
  if (tier === "DEV_ONLY") {
    return process.env.REACT_APP_INTEGRATION_SUITE_URL ?? "";
  }
  return process.env.REACT_APP_WEB_SUITE_URL ?? "";
};
