/**
 * Get company's subscription
 */
const getSubscription = async (
  userToken: string,
  companyId: string
): Promise<APIResponseGetSubscription> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/org/${companyId}/subscription`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `bearer ${userToken}`,
      "content-type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export default getSubscription;
