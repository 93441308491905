declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const addGTMEvent = (event: Record<string, any>) => {
  window.dataLayer.push(event);
};

export default addGTMEvent;
