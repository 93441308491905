import { LoadingButton } from "@mui/lab";
import { Alert, Box, Skeleton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import getCountries from "../../network/get-countries";
import getProduct from "../../network/get-product";
import { getUserToken } from "../../utils/account/common";

interface LoaderProps {
  language: Language;
  productId: string | null;
  onComplete: (product: Product, isUserLoggedIn: boolean) => void;
}
/**
 * Intermediate load component
 * Its purpose is to validate product parameter
 * before sending user to the onboarding flow
 */
const Loader = ({ language, productId, onComplete }: LoaderProps) => {
  const { data: product } = useQuery({
    queryKey: ["product", productId],
    queryFn: async () => {
      return await getProduct({ language, productId });
    },
  });

  /**
   * Warm up country cache to enable quicker load
   * of country selector
   */
  const { data: countries } = useQuery({
    queryKey: ["countries", language],
    queryFn: () => getCountries(language),
  });

  /**
   * Load usertoken to see if visitor is already logged in
   */
  const { data: userToken, isLoading: isUserTokenLoading } = useQuery({
    queryKey: ["usertoken"],
    queryFn: getUserToken,
  });

  /**
   * Fire complete event as side-effect to prevent
   * updating while rendering. Otherwise the parent component's
   * state might update while it renders.
   */
  useEffect(() => {
    const ready = product && countries && !isUserTokenLoading;
    if (ready) {
      onComplete(product.properties, !!userToken);
    }
  }, [product, countries, userToken, isUserTokenLoading, onComplete]);

  if (productId === null) {
    return <Alert severity="error">Missing productId parameter</Alert>;
  }

  if (product === null) {
    return (
      <Alert severity="error">
        Failed loading product with id <em>{productId}</em>
      </Alert>
    );
  }

  return (
    <>
      <Box mb={6}>
        <Skeleton variant="rectangular" height={24} />
      </Box>
      <Box mb={3}>
        <Skeleton variant="rectangular" height={24} />
      </Box>
      <Grid2 container rowSpacing={2} columnSpacing={4} mb={5}>
        <Grid2 xs={12} md={6}>
          <Skeleton height={84} />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Skeleton height={84} />
        </Grid2>
      </Grid2>
      <Stack direction="row" display="flex" justifyContent="flex-end">
        <LoadingButton variant="contained" disabled>
          Next
        </LoadingButton>
      </Stack>
    </>
  );
};

export default Loader;
