import config from "../config";

interface CountryList
  extends Array<{
    code: string;
    label: string;
  }> {}

/**
 * Get localized list of countries
 */
const getCountries = async (
  language: Language | "cimode"
): Promise<CountryList> => {
  const normalizedLanguage = language === "cimode" ? "en" : language;
  const response = await fetch(
    `${config.ASSETS_HOST}/json/countries/countries_${normalizedLanguage}.json`
  );
  return response.json();
};

export default getCountries;
