import { AuthenticationDetails } from "amazon-cognito-identity-js";
import { newCognitoUser } from "./common";

const confirm = (email: string, verificationCode: string, password: string) => {
  return new Promise<void>((resolve, reject) => {
    const cognitoUser = newCognitoUser(email);
    cognitoUser.confirmRegistration(verificationCode, true, (err) => {
      if (err) {
        return reject(err);
      }
      const authenticationDetails = new AuthenticationDetails({
        Username: email.toLowerCase(),
        Password: password,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          return resolve();
        },
        onFailure: (err1) => {
          return reject(err1);
        },
      });
    });
  });
};

export const resendConfirmationCode = (email: string) => {
  const cognitoUser = newCognitoUser(email);
  return new Promise<void>((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err) => {
      if (err) {
        return reject(err.message);
      }
      return resolve();
    });
  });
};

export default confirm;
