export type CompletedStep =
  | "none"
  | "load"
  | "companyInformation"
  | "contactDetails"
  | "accountVerification"
  | "paymentMethod";

export type View =
  | "Loader"
  | "CompanyInformation"
  | "ContactDetails"
  | "AccountVerification"
  | "PaymentMethod"
  | "RegistrationConfirmation"
  | "RedirectToApp";

export const calculateView = (
  cs: CompletedStep,
  requiresPayment: boolean,
  loggedIn: boolean
): View => {
  if (loggedIn) {
    return "RedirectToApp";
  }
  // Skip payment step when payment not required
  if (!requiresPayment) {
    switch (cs) {
      case "none": {
        return "Loader";
      }
      case "load": {
        return "CompanyInformation";
      }
      case "companyInformation": {
        return "ContactDetails";
      }
      case "contactDetails": {
        return "AccountVerification";
      }
      case "accountVerification": {
        return "RegistrationConfirmation";
      }
    }
  }
  switch (cs) {
    case "none": {
      return "Loader";
    }
    case "load": {
      return "CompanyInformation";
    }
    case "companyInformation": {
      return "ContactDetails";
    }
    case "contactDetails": {
      return "AccountVerification";
    }
    case "accountVerification": {
      return "PaymentMethod";
    }
    case "paymentMethod": {
      return "RegistrationConfirmation";
    }
  }
};

export const calculateStep = (cs: CompletedStep): number => {
  switch (cs) {
    case "load": {
      return 0;
    }
    case "none": {
      return 0;
    }
    case "companyInformation": {
      return 1;
    }
    case "contactDetails": {
      return 2;
    }
    case "accountVerification": {
      return 3;
    }
    case "paymentMethod": {
      return 4;
    }
  }
};

export const steps = (loading: boolean, requiresPayment: boolean) => {
  if (loading) {
    return ["", "", "", ""];
  }
  if (requiresPayment) {
    return [
      "processStep.registrationNumber",
      "processStep.contactDetails",
      "processStep.verify",
      "processStep.paymentMethod",
    ];
  }
  return [
    "processStep.registrationNumber",
    "processStep.contactDetails",
    "processStep.verify",
  ];
};

export const productRequiresPayment = (product?: Product) => {
  if (!product) {
    return false;
  }
  return product.details.subscriptionStatus === "PRODUCTION";
};

export const getQueryParam = <T>(key: string): T | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key) as T;
};
