import {
  Paper,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Logo from "./components/logo/Logo";
import { Container, Stack } from "@mui/system";
import LanguageSelector from "./components/language-selector/LanguagSelector";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CompanyInformation from "./views/company-information/CompanyInformation";
import { useState } from "react";
import ContactDetails from "./views/contact-details/ContactDetails";
import AccountVerification from "./views/account-verification/AccountVerification";
import DevToolbar from "./components/dev-toolbar/DevToolbar";
import PaymentMethod from "./views/payment-method/PaymentMethod";
import RegistrationConfirmation from "./views/registration-confirmation/RegistrationConfirmation";
import Loader from "./views/loader/Loader";
import {
  CompletedStep,
  calculateView,
  productRequiresPayment,
  steps,
  calculateStep,
  getQueryParam,
} from "./App.utils";
import Hubspot from "./components/hubspot/Hubspot";
import useQueryParameter from "./hooks/use-query-parameter";
import RedirectToApp from "./views/redirect-to-app/RedirectToApp";

const getCountryQueryParam = () => getQueryParam<string>("country");

function App() {
  const initialSignupInformation: SignupInformation = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    companyId: "",
    country: getCountryQueryParam() ?? "",
    companyName: "",
    streetAddress: "",
    zip: "",
    city: "",
    vat: "",
    parent: "",
    signUpComment: "",
  };
  const [completedStep, setCompletedStep] = useState<CompletedStep>("none");
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [language, setLanguage] = useQueryParameter<Language>("hl", "en");
  const { t, i18n, ready } = useTranslation();
  const [signupInformation, setSignupInformation] = useState<SignupInformation>(
    initialSignupInformation
  );
  const [productId] = useQueryParameter<string>("productId", "");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  if (!ready) {
    return null;
  }

  const view = calculateView(
    completedStep,
    productRequiresPayment(product),
    loggedIn
  );

  return (
    <>
      {process.env.NODE_ENV === "development" && (
        <DevToolbar
          onLoadClick={() => {
            setProduct(undefined);
            setCompletedStep("none");
          }}
          onCompanyInformationClick={() => {
            setCompletedStep("load");
          }}
          onContactDetailsClick={(information: CompanyInformation) => {
            setSignupInformation({
              ...signupInformation,
              ...information,
            });
            setCompletedStep("companyInformation");
          }}
          onAccountVerificationClick={(contactDetails: ContactDetails) => {
            setSignupInformation({
              ...signupInformation,
              ...contactDetails,
            });
            setCompletedStep("contactDetails");
          }}
          onPaymentMethodClick={() => setCompletedStep("accountVerification")}
          onRegistrationConfirmationClick={() =>
            setCompletedStep("paymentMethod")
          }
        />
      )}
      <Container maxWidth="md">
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
          mb={4}
        >
          <Logo />
          <LanguageSelector
            language={language}
            onChange={(language) => setLanguage(language)}
          />
        </Stack>
        <Stack display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" color="primary">
            {t("app.title")}
          </Typography>
          <Stepper
            activeStep={calculateStep(completedStep)}
            alternativeLabel
            sx={{
              width: { xs: "100%", md: "64%" },
              my: 5,
            }}
          >
            {steps(!product, productRequiresPayment(product)).map(
              (label, i) => (
                <Step key={i}>
                  <StepLabel>
                    {label.length === 0 ? (
                      <Skeleton variant="text" />
                    ) : (
                      t(label)
                    )}
                  </StepLabel>
                </Step>
              )
            )}
          </Stepper>
        </Stack>
        <Stack display="flex" alignItems="center">
          <Paper sx={{ p: 4, width: { md: "80%", sm: "100%" } }}>
            {view === "Loader" && (
              <Loader
                language={language}
                productId={productId}
                onComplete={(product: Product, isUserLoggedIn: boolean) => {
                  setProduct(product);
                  setCompletedStep("load");
                  setLoggedIn(isUserLoggedIn);
                }}
              />
            )}
            {view === "CompanyInformation" && (
              <CompanyInformation
                country={signupInformation.country}
                registrationNumber={signupInformation.companyId}
                language={language}
                productName={product?.labels?.name ?? ""}
                onSubmit={(information) => {
                  setSignupInformation({
                    ...signupInformation,
                    ...information,
                  });
                  setCompletedStep("companyInformation");
                }}
              />
            )}
            {view === "ContactDetails" && (
              <ContactDetails
                language={language}
                signupInformation={signupInformation}
                productName={product?.labels?.name ?? ""}
                productId={product?.id ?? ""}
                onBack={() => setCompletedStep("load")}
                onSubmit={(information: ContactDetails) => {
                  setSignupInformation({
                    ...signupInformation,
                    ...information,
                  });
                  setCompletedStep("contactDetails");
                }}
              />
            )}
            {view === "AccountVerification" && (
              <AccountVerification
                companyName={signupInformation.companyName}
                email={signupInformation.email}
                password={signupInformation.password}
                productName={product?.labels?.name ?? ""}
                onSubmit={() => {
                  setCompletedStep("accountVerification");
                }}
              />
            )}
            {view === "PaymentMethod" && (
              <PaymentMethod
                language={language}
                signupInformation={signupInformation}
                onSubmit={() => {
                  setCompletedStep("paymentMethod");
                }}
              />
            )}
            {view === "RegistrationConfirmation" && (
              <RegistrationConfirmation product={product} />
            )}
            {view === "RedirectToApp" && <RedirectToApp />}
          </Paper>
        </Stack>
      </Container>
      <Hubspot id={process.env.REACT_APP_HUBSPOT_ID} />
    </>
  );
}

export default App;
