import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

//import { useTranslation } from "react-i18next";

const StyledSelect = styled(Select)(({ theme }) => ({
  padding: 0,
  margin: 0,
  fontSize: "0.875rem",
  color: theme.palette.primary.main,
  "& .MuiSelect-iconStandard": {
    fill: theme.palette.primary.main,
  },
}));

interface LanguageSelectorProps {
  language: Language;
  onChange: (language: Language) => void;
}

const LanguageSelector = ({ language, onChange }: LanguageSelectorProps) => {
  const { t } = useTranslation();
  const options: Array<Language> = ["en", "sv", "da", "no"];
  return (
    <StyledSelect
      variant="standard"
      value={language}
      disableUnderline
      onChange={({ target: { value } }) => {
        onChange(value as Language);
      }}
    >
      {options.map((language) => (
        <MenuItem key={language} value={language}>
          {t(`languageSelector.${language}`)}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

LanguageSelector.propTypes = { language: PropTypes.string };

export default LanguageSelector;
