interface OverviewReponseProperties extends CompanyInformation {
  status: CompanySubmitStatus;
  billingOptions: Array<Billing>;
}

interface OverviewResponse {
  properties: OverviewReponseProperties;
}

const getCompanyOverview = async (
  country: string,
  registrationNumber: string
): Promise<OverviewResponse> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${country}/overview/${registrationNumber}`;
  const response = await fetch(url, {
    method: "GET",
    headers: { Accept: "application/json" },
  });
  return response.json();
};

export default getCompanyOverview;
