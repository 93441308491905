import { useState } from "react";
import { getQueryParam } from "../../App.utils";

/**
 * Hook used to get and set query parameter
 */
const useQueryParameter = <T>(
  name: string,
  initialValue: T
): [T, (v: T) => void] => {
  const [value, setValue] = useState<T>(
    getQueryParam<T | null>(name) || initialValue
  );

  const onSetValue = (value: T) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(name, value as string);
    const newRelativePathQuery =
      window.location.pathname + "?" + searchParams.toString();
    window.history.pushState(null, "", newRelativePathQuery);
  };
  const observer = new MutationObserver(() => {
    const newValue = getQueryParam<T | null>(name);
    if (newValue && newValue !== value) {
      setValue(newValue);
    }
  });
  observer.observe(document, { subtree: true, childList: true });

  return [value, onSetValue];
};

export default useQueryParameter;
