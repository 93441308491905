import BackIcon from "@mui/icons-material/NavigateBefore";
import NextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface TermsProps {
  open: boolean;
  language: string;
  productId: string;
  onAccept: () => void;
  onClose: () => void;
}

type Agreement = {
  content: string;
  createdAt: string;
  details: {
    version: string;
  };
};

interface TermsResponse {
  properties: {
    elements: {
      properties: Agreement;
    }[];
  };
}

const Terms = ({
  open,
  language,
  onAccept,
  onClose,
  productId,
}: TermsProps) => {
  const { t } = useTranslation();
  const [pageNum, setPageNum] = useState(1);
  const { data: agreements } = useQuery(["terms", language], async () => {
    const url = `${
      process.env.REACT_APP_BACKEND_URL ?? ""
    }/product/${productId}/agreements`;
    const response = await fetch(url, {
      headers: { Accept: "application/json" },
    });
    const data = await response.json();
    const terms = data as TermsResponse;
    return terms.properties.elements.map((element) => element.properties);
  });

  const formatDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const formattedDate = date?.toISOString().split("T");
    return formattedDate[0];
  };
  const max = (agreements && agreements.length) || 0;
  const selectedAgreement = agreements && agreements[pageNum - 1];
  const formattedDate =
    selectedAgreement?.details.version ||
    (selectedAgreement?.createdAt
      ? formatDate(selectedAgreement.createdAt)
      : "—");

  return (
    <Dialog open={open} disableAutoFocus onClose={onClose} scroll="paper">
      <Box
        sx={{ pt: 5, px: 5, overflowY: "scroll" }}
        dangerouslySetInnerHTML={{ __html: selectedAgreement?.content ?? "" }}
      />
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Typography
          sx={{ fontWeight: 500, ml: 1 }}
        >{`Version: ${formattedDate}`}</Typography>
        {max > 0 && (
          <>
            <IconButton
              color="primary"
              size="small"
              disabled={pageNum === 1 || max === 0}
              onClick={() => setPageNum((prevState) => prevState - 1)}
            >
              <BackIcon />
            </IconButton>
            <Box sx={{ ml: 1, minWidth: 40, textAlign: "center" }}>
              <Typography color="primary">{`${pageNum}${
                max ? ` (${max})` : ""
              }`}</Typography>
            </Box>
            <IconButton
              color="primary"
              size="small"
              disabled={pageNum === max || max === 0}
              onClick={() => setPageNum((prevState) => prevState + 1)}
            >
              <NextIcon />
            </IconButton>
          </>
        )}
        <Button
          onClick={onAccept}
          color="primary"
          disabled={max > pageNum}
          variant="contained"
        >
          {t("terms.button.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Terms;
