import { Button, Link, Skeleton, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { productRequiresPayment } from "../../App.utils";
import getOrganizations from "../../network/get-organizations";
import getSubscription from "../../network/get-subscription";
import { getUserToken } from "../../utils/account/common";
import { generateAppUrl } from "./utils";

interface RegistrationConfirmationProps {
  product?: Product;
}

const RegistrationConfirmation = ({
  product,
}: RegistrationConfirmationProps) => {
  const { t } = useTranslation();
  const { data: organization, isLoading: organizationLoading } =
    useQuery<APIResponseGetOrganization>({
      queryKey: ["organization"],
      queryFn: async () => {
        const userToken = (await getUserToken()) as string;
        return getOrganizations(userToken);
      },
    });

  const organizationId = organization?.properties.elements[0].properties.id;
  const { data: subscription, isLoading: subscriptionLoading } = useQuery({
    enabled: !!organization,
    queryKey: ["subscription", organizationId],
    queryFn: async () => {
      const userToken = (await getUserToken()) as string;
      if (!organizationId) {
        return null;
      }
      return getSubscription(userToken, organizationId);
    },
  });

  const companyDetails =
    organization?.properties.elements[0].properties.details;
  const companyName = organization?.properties.elements[0].name;
  const reference = companyDetails?.reference;
  const billing = subscription?.properties.details.billing;
  const invoiceEmail =
    organization?.properties.elements[0].properties.details.invoiceEmails[0];
  const appUrl = generateAppUrl(product);
  const name = product?.labels?.name ?? "";
  const requiresPayment = productRequiresPayment(product);

  const nextDueDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  const formatDate = (date: Date) => {
    const formattedDate = date?.toISOString().split("T");
    return formattedDate[0];
  };

  return (
    <>
      <Stack display="flex" alignItems="center" mb={4}>
        <Typography variant="h6">
          {t("step.registrationConfirmation.title", { productName: name })}{" "}
        </Typography>
      </Stack>
      <Grid2 container rowSpacing={2} columnSpacing={8} mb={4}>
        <Grid2 xs={12} md={6}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t("step.registrationConfirmation.information.company.label")}
          </Typography>
          <Typography variant="body2">
            {organizationLoading && (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </>
            )}
            {organization && (
              <>
                {companyName}
                <br />
                {companyDetails?.streetAddress}
                <br />
                {companyDetails?.zip} {companyDetails?.city}
              </>
            )}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t("step.registrationConfirmation.information.reference.label")}
          </Typography>
          <Typography variant="body2">
            {organizationLoading && <Skeleton variant="text" />}
            {reference}
          </Typography>
        </Grid2>
        {requiresPayment && (
          <>
            <Grid2 xs={12} md={6}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {t(
                  "step.registrationConfirmation.information.paymentMethod.label"
                )}
              </Typography>
              <Typography variant="body2">
                {billing &&
                  (billing === "send_invoice"
                    ? t(
                        "step.registrationConfirmation.paymentMethod.invoice.label"
                      )
                    : t(
                        "step.registrationConfirmation.paymentMethod.creditCard.label"
                      ))}
                {!billing && <Skeleton variant="text" />}
              </Typography>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {t(
                  "step.registrationConfirmation.information.nextInvoiceDate.label"
                )}
              </Typography>
              <Typography variant="body2">
                {subscriptionLoading && <Skeleton variant="text" />}
                {subscription && formatDate(nextDueDate)}
              </Typography>
            </Grid2>
          </>
        )}
        {requiresPayment && (
          <Grid2 xs={12} md={12}>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {t(
                "step.registrationConfirmation.information.invoiceRecipient.label"
              )}
            </Typography>
            {organizationLoading && (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </>
            )}
            {organization && (
              <>
                <Typography variant="body2">{invoiceEmail}</Typography>
                <Typography variant="caption">
                  {t(
                    "step.registrationConfirmation.information.invoiceRecipient.description"
                  )}
                </Typography>
              </>
            )}
          </Grid2>
        )}
      </Grid2>
      <Stack direction="row" display="flex" justifyContent="center">
        <Button component={Link} href={appUrl}>
          {t("button.getStarted")}
        </Button>
      </Stack>
    </>
  );
};

export default RegistrationConfirmation;
