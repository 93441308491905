interface Information {
  /**
   * Name of reference
   */
  reference: string;

  /**
   * Billing type
   */

  billing: Billing;
  /**
   * Optional Stripe card token
   * Must be provided when subscription is paid with card
   */
  cardToken?: string;
}

/**
 * Updates subscription
 *
 * Returns `Promise` with raw response
 */
const updateSubscription = async (
  userToken: string,
  organizationId: string,
  { reference, billing, cardToken }: Information
): Promise<APIResponseGetSubscription> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/org/${organizationId}/subscription`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `bearer ${userToken}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({ reference, billing, token: cardToken }),
  });
  return await response.json();
};

export default updateSubscription;
