import {
  CognitoUserAttribute,
  ISignUpResult,
  NodeCallback,
} from "amazon-cognito-identity-js";
import { getUserPool } from "./common";

const signup = (
  information: SignupInformation,
  language: Language,
  productId: string,
  callback: NodeCallback<Error, ISignUpResult>
) => {
  const {
    email,
    firstName,
    lastName,
    companyId,
    country,
    companyName,
    streetAddress,
    city,
    zip,
    vatStatus,
    vatType,
    vat,
    password,
    signUpComment,
  } = information;
  const userPool = getUserPool();
  //    const parentObj = parent && parent.orgId ? {orgId: parent.orgId, type: 'default'} : undefined
  const parentObj = undefined;
  const attributeList = [
    new CognitoUserAttribute({
      Name: "email",
      Value: email.toLowerCase(),
    }),
    new CognitoUserAttribute({
      Name: "given_name",
      Value: firstName,
    }),
    new CognitoUserAttribute({
      Name: "family_name",
      Value: lastName,
    }),
    new CognitoUserAttribute({
      Name: "custom:json",
      Value: JSON.stringify({
        attr1: companyId,
        attr2: country,
        attr3: companyName,
        attr4: {
          streetAddress,
          city,
          zip,
          vat,
          searchableName: companyName ? companyName.toLowerCase() : "",
          reference: `${firstName} ${lastName}`,
          parent: parentObj,
          productId,
          vatStatus,
          vatType,
        },
      }),
    }),
    new CognitoUserAttribute({
      Name: "custom:language",
      Value: language,
    }),
    new CognitoUserAttribute({
      Name: "custom:signUpComment",
      Value: signUpComment,
    }),
  ];
  userPool.signUp(email.toLowerCase(), password, attributeList, [], callback);
};

export default signup;
