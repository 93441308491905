import { colors } from "../../assets/themes/roaring";

import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const Logo = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x={0}
    y={0}
    sx={{
      height: 32,
      fill: colors.logo,
      width: "auto",
    }}
    viewBox="0 0 322 99.7"
  >
    <path d="M33.4 36.9c-1.6-.3-2.7-.6-4.9-.6-7.4 0-13.2 3.4-13.2 14.2v25.2H1V46.5C1 36 8.5 26.9 16 24c7.5-2.9 17.4-1.8 17.4-1.8v14.7zM183.4 36.9c-1.6-.3-2.7-.6-4.9-.6-7.4 0-13.2 3.4-13.2 14.2v25.2H151V46.5c0-10.4 7.5-19.6 15-22.5 7.5-2.9 17.4-1.8 17.4-1.8v14.7zM77.9 49c0-10.1-6.5-15.1-13.5-15.1S50.9 39 50.9 49c0 9.9 6.5 15.1 13.5 15.1S77.9 59 77.9 49m14.4 0c0 16.3-12 28.2-27.9 28.2S36.5 65.3 36.5 49c0-16.4 12-28.2 27.9-28.2S92.3 32.6 92.3 49M130 54.1v-2.4l-12 1.8c-3.7.5-6.6 2.6-6.6 6.7 0 3.1 2.3 6.2 6.9 6.2 6.1 0 11.7-2.9 11.7-12.3M112.9 45l13.1-2c3-.4 4-1.9 4-3.8 0-3.8-2.9-6.9-9-6.9-6.3 0-9.7 4-10.2 8.6L98 38.2c.9-8.3 8.5-17.5 22.8-17.5 16.9 0 23.1 9.5 23.1 20.2v16.3c0 5.9-.2 15.2-12.5 18.3-2 .5-6.8 2.1-15.9 1.5-11.3-.7-18.6-7.7-18.6-16 .1-9.4 7.1-14.7 16-16M189.8 22.4h14.4v53.2h-14.4zM226.4 75.6H212V41.5S212 21 236.3 21c15.8 0 24.3 9.4 24.3 21.1v33.5h-14.4v-31c0-5.9-2.9-10.6-9.8-10.6-6.3 0-9.9 4.9-9.9 11v30.6zM294.5 60.9c-7.5 0-12.8-5.2-12.8-13.5 0-8.2 5.6-13.4 12.8-13.4 6.9 0 12.5 5.2 12.5 13.4.1 8.2-5.1 13.5-12.5 13.5M292 21.4c-14.4 0-24.8 11.9-24.8 25.9 0 14.9 10.6 25.8 24.8 25.8 7.7 0 12.7-3.4 14.8-6.8v3.9c0 10.3-5.1 14.8-14.2 14.8-1 0-2-.1-2.9-.3L286.3 97c2.1.5 4.4.7 6.8.7 20 0 27.9-13.3 27.9-27.9V47.6c0-3.6-2-26.2-29-26.2M197 19.1c4.8 0 8.7-3.9 8.7-8.7 0-4.8-3.9-8.7-8.7-8.7-4.8 0-8.7 3.9-8.7 8.7 0 4.8 3.9 8.7 8.7 8.7" />
  </SvgIcon>
);

export default Logo;
