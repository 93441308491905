/**
 * Get users organizations
 */
const getOrganizations = async (
  userToken: string
): Promise<APIResponseGetOrganization> => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/orgs`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `bearer ${userToken}`,
    },
  });
  const data = await response.json();
  return data;
};

export default getOrganizations;
